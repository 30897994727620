<template>
   <div>

      <!-- Breadcrumb start -->
      <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_2 font_bold mb_23">Working from the office <span class="text_primary">guide</span>
                     </h2>
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><router-link class="navbar-brand m-0 p-0"
                                 :to="{ name: 'user_home' }">
                                 Home
                              </router-link></li>
                           <li class="breadcrumb-item active" aria-current="page">Working From Office</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <section :class="!company_code ? 'work_from_office how_else_ergo mb-0' : 'work_from_office how_else_ergo'">
         <!-- <img src="../assets/images/work-from-office/first-section.png" alt="img" class="first_section"> -->
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xxl-12">
                  <div class="row">
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'wfo_seating' }">
                              <img src="../assets/images/work-from-office/work_from_office1.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal1">
                                 <img src="../assets/images/home-essentials/wfo_seating.png" alt="img" class="mb_66">
                              </a>  -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Seating</h5>
                                 <p class="text-center font_size_18 mb_20 text_balticsea">
                                    Learn how to setup your chair so that you can sit comfortably all day long.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'seating_your_desk' }">
                              <img src="../assets/images/work-from-office/work_from_office2.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                                 <img src="../assets/images/home-essentials/your_desk.png" alt="img" class="mb_66">
                              </a> -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Desk</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Wondering how to best setup your desk? We’ve got you covered.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'seating_your_keybord' }">
                              <img src="../assets/images/work-from-office/work_from_office3.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                                 <img src="../assets/images/home-essentials/seating_keyboard.png" alt="img" class="mb_66">
                              </a> -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Keyboard & Mouse</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Learn how to keep your keyboard and mouse close to you
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    10 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'seating_your_monitor' }">
                              <img src="../assets/images/work-from-office/work_from_office4.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal4">
                                 <img src="../assets/images/home-essentials/seating_monitor.png" alt="img" class="mb_66">
                              </a> -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Monitors & Screens</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Your eyes will love the new placement of your screens.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'seating_your_meeting' }">
                              <img src="../assets/images/work-from-office/work_from_office5.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal5">
                                 <img src="../assets/images/home-essentials/seating_screen.png" alt="img" class="mb_66">
                              </a> -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Meeting Rooms</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    You can still be ergo savvy when working in meetings.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'everyday_stretches' }">
                              <img src="../assets/images/work-from-office/work_from_office6.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                                 <img src="../assets/images/home-essentials/everyday_stretches.png" alt="img" class="mb_66">
                              </a> -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Everyday Stretches</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Give your body a break with these feel good stretches.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="take_an_ergonomic_parttwo mb_44" v-show="company_code">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xl-10">
                  <div class="ergonomic-image pt_26 pb_26 ">
                     <div class="overlay">
                        <div class="row">
                           <div class="col-md-12 col-lg-7 col-xxl-6">
                              <div class="left-section pl_60 pr_60">
                                 <h6 class="sub_heading_1 font_bold mb_22 text-white">Take an ergonomic self-assessment
                                 </h6>
                                 <p class="text-white p_20 mb_54">Take a self-assessment today and see how ergo can help
                                    you feel great, enjoy more energy and supercharge your productivity. </p>
                                 <div class="mt_14">
                                    <router-link :to="{ name: 'self_assessment_signup' }"
                                       class="btn btn_secondary font_size_16">Take a Self-Assessment</router-link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!--<div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
               <div class="modal-body">               
                  <div class="video_elements">
                     <a href="#" class="modal_close"  data-bs-dismiss="modal" aria-label="Close"><img src="../assets/images/close_modal.png"></a>
                        <iframe src="https://player.vimeo.com/video/586041508?h=c9ff799e3a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Happy Feet"></iframe>
                     </div>
               </div>
            </div>
         </div>
      </div>
     <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
               <div class="modal-body">                  
                  <div class="video_elements">
                     <a href="#" class="modal_close"  data-bs-dismiss="modal" aria-label="Close"><img src="../assets/images/close_modal.png"></a>
                        <iframe src="https://player.vimeo.com/video/586047402?h=eed1da36da&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Posture Perfect (Standing)"></iframe>
                  </div>
               </div>
            </div>            
         </div>
      </div>
     <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
         <div class="modal-content">
            <div class="modal-body">
               
            <div class="video_elements">
               <a href="#" class="modal_close"  data-bs-dismiss="modal" aria-label="Close"><img src="../assets/images/close_modal.png"></a>
                           <iframe src="https://player.vimeo.com/video/661817205?h=ea02d75ab4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Your Keyboard and Mouse"></iframe>
               </div>
            </div>

         </div>
      </div>
      </div>
     <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
         <div class="modal-content">
            <div class="modal-body">
               
            <div class="video_elements">
               <a href="#" class="modal_close"  data-bs-dismiss="modal" aria-label="Close"><img src="../assets/images/close_modal.png"></a>
                  <iframe src="https://player.vimeo.com/video/586046799?h=cc4a2ef978&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Monitor Madness"></iframe>
               </div>
            </div>

         </div>
      </div>
      </div>
     <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
         <div class="modal-content">
            <div class="modal-body">
               
            <div class="video_elements">
               <a href="#" class="modal_close"  data-bs-dismiss="modal" aria-label="Close"><img src="../assets/images/close_modal.png"></a>
                  <iframe src="https://player.vimeo.com/video/661643703?h=b0b76e1502&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="How To Avoid Zoom Fatigue"></iframe>
               </div>
            </div>

         </div>
      </div>
      </div>
     <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal6" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
         <div class="modal-content">
            <div class="modal-body">
               
            <div class="video_elements">
               <a href="#" class="modal_close"  data-bs-dismiss="modal" aria-label="Close"><img src="../assets/images/close_modal.png"></a>
                  <iframe
                        src="https://player.vimeo.com/video/139246010?h=83e1f57f7c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                        title="Neck Side Bend Stretch"></iframe>
               </div>
            </div>

         </div>
      </div>
      </div> -->
   </div>
</template>

<script>
export default {
   data() {
      return {
         company_code: true,
      }
   },
   name: "Work_from_office",
   mounted() {
      document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
      let data = this.$store.getters["auth/authUser"];
      let company_data = data.client;
      if(company_data.self_assessment_button_status == 0){
         this.company_code = false;
      }
      if (
         localStorage.getItem("userToken") == null ||
         localStorage.getItem("employeeEmail") == null
      ) {
         this.$router.push({ name: "login" }).catch(() => true);
      }
   }
}
</script>